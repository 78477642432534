<template>
  <div id="Login">
    <el-form size="mini">
      <el-form-item>
        <h2>后台管理</h2>
      </el-form-item>
      <el-form-item>
        <el-input placeholder="账号" size="mini" v-model="username"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="密码"
          size="mini"
          v-model="password"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" plain @click="login"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login() {
      if (!this.username || !this.password) {
        this.$message.error("账号密码不能为空");
        return;
      }
      this.$http({
        url: "/login",
        method: "POST",
        data: {
          username: this.username,
          password: this.password
        }
      }).then(response => {
        window.localStorage.setItem("userInfo", JSON.stringify(response));
        let path = "";
        if (response.is_admin) {
          path = "/user_management";
        } else {
          path = "/data_class";
        }
        this.$router.push(path);
      });
    }
  }
};
</script>

<style scoped>
#Login {
  height: 100vh;
  background-color: #393d49;
}
#Login .el-form {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
  padding: 0 20px;
  width: 300px;
  border-radius: 5px;
  background-color: #ffffff;
}
#Login .el-form h2 {
  text-align: center;
}
#Login .el-form .el-button {
  width: 100%;
}
</style>
